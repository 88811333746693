import PropTypes from 'prop-types';
import React from 'react';
import { getPagePath } from '../../utils/appRoutes';

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { ARD_SEARCH } from '../../utils/appRoutes.definitions';
import RelatedCategories from '../RelatedCategories';

const SearchBreadcrumb = ({ categories = [], classList = {}, isMobile = false }) => {
  const items = categories.reduce((acc, { name, slug }, idx) => {
    let categoryPath = '';
    if (categories[idx]?.slug) {
      for (let i = 0; i <= idx; i++) {
        if (categories[i]?.slug) {
          categoryPath = categoryPath + '/' + categories[i].slug;
        }
      }
    }
    const url = getPagePath(ARD_SEARCH, categoryPath);
    return [...acc, { slug, text: name, url, ignoreExistingQueryString: false }];
  }, []);

  const mobileItem = items.filter((_, index) => index !== items.length - 1);

  if (isMobile) {
    return <RelatedCategories items={mobileItem} />;
  }

  return <Breadcrumb classList={{ root: classList.root }} items={items} />;
};

SearchBreadcrumb.displayName = 'SearchBreadcrumb';
SearchBreadcrumb.propTypes = {
  categories: PropTypes.array,
};

export default SearchBreadcrumb;
