import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useOnlineStatus from '@rehooks/online-status';
import { isServer } from 'Utils/isServer';
import { useCategoryRouteValidator } from 'Utils/useCategoryRouteValidator';
import { SSRContext } from 'Utils/SSRContext';
import loadable from '@loadable/component';
import loadVisibleComponent from 'Utils/loadVisibleComponent';
import { isCurrentPath } from '../../utils/appRoutes';
import { ARD_PERMA_SEARCH, ARD_SEARCH } from '../../utils/appRoutes.definitions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const NotFoundPage = loadable(() => import('../NotFoundPage/NotFoundPage'));
const OfflinePage = loadVisibleComponent(() => import('../OfflinePage/OfflinePage'));

export const SRPWrapper = ({ staticContext, children }) => {
  const { replace } = useHistory();
  const online = useOnlineStatus();
  const location = useLocation();
  const { categoryPath, slug, uberfilter } = useParams();

  const { isValidCategorySSR, SSRPath } = useContext(SSRContext);
  const isSRP = isCurrentPath(ARD_SEARCH, location) || isCurrentPath([ARD_PERMA_SEARCH, slug, uberfilter], location);
  const isValidCategory = useCategoryRouteValidator(isValidCategorySSR, categoryPath, SSRPath);

  const get404Page = () => {
    //We have no direct access to StaticRouter's context, but this way we can set context.url
    //Further actions are described in src/server/index.js
    if (isServer) replace('/404/');
    if (!online) return <OfflinePage />;
    return <NotFoundPage />;
  };

  if (isSRP) return children;
  if (isValidCategory === false) {
    if (staticContext) staticContext.statusCode = 404;
    return get404Page();
  }
  return children;
};
